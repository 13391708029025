import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/login/Login.vue";
import Password from "@/views/login/Password.vue";

Vue.use(VueRouter);

const routes = [
  // 根目录重定向
  {
    path: "/", // 根目录
    redirect: "/login/password" //重定向
    // component: Home
  },
  //登录页面
  {
    path: "/login",
    name: "Login",
    component: Login,
    children: [
      {
        path: "password",
        name: "Password",
        component: Password
      },
      {
        path: "code",
        name: "Code",
        component: () => import("../views/login/Code.vue") //懒加载
      },
      {
        path: "forgetpwd",
        name: "Forgetpwd",
        component: () => import("../views/login/Forgetpwd.vue")
      }
    ]
  },

  //首页
  {
    path: "/index",
    name: "Index",
    component: () => import("@/views/BMap/BMap.vue")
  },
  //订单列表
  {
    path: "/order-list",
    name: "orderList",
    component: () => import("@/views/orderList.vue")
  },
  {
    path: "/tables",
    name: "Tables",
    component: () => import("../views/tablebrowse/Tables.vue")
  },
  {
    path: "/create",
    name: "Create",
    component: () => import("../views/Create.vue")
  },
  // 我的项目
  {
    path: "/project",
    name: "Project",
    component: () => import("../views/project/Project.vue")
  },
  // 常见问题
  {
    path: "/problem",
    name: "Problem",
    component: () => import("../views/commonproblem/Problem.vue")
  },
  // 消息提醒
  {
    path: "/reminder",
    name: "Reminder",
    component: () => import("../views/messagereminder/Reminder")
  },
  // 我的草稿
  {
    path: "/draft",
    name: "Draft",
    component: () => import("../views/project/Draft.vue")
  },
  // 我的发布
  {
    path: "/release",
    name: "Release",
    component: () => import("../views/project/Release.vue")
  },
  {
    path: "/mytask",
    name: "Mytask",
    component: () => import("../views/project/Mytask.vue")
  },
  // 设计审核
  {
    path: "/review",
    name: "Review",
    component: () => import("../views/audit/Review.vue")
  },
  {
    path: "/audit/:id",
    name: "Audit",
    component: () => import("../views/audit/Audit.vue")
  },
  {
    path: "/record/:id/:title",
    name: "Record",
    component: () => import("../views/audit/Record.vue")
  },
  //个人中心
  {
    path: "/personal",
    name: "Personal",
    component: () => import("../views/personal/Personal.vue")
  },
  //员工中心
  {
    path: "/staff",
    name: "Staff",
    component: () => import("@/views/staff/Staff.vue")
  },
  //角色管理
  {
    path: "/role",
    name: "role",
    component: () => import("@/views/role/role.vue")
  },
  //角色权限修改
  {
    path: "/role/jurisdiction",
    name: "jurisdiction",
    component: () => import("@/views/role/jurisdiction/jurisdiction.vue")
  },
  //组织管理
  {
    path: "/organization",
    name: "organization",
    component: () => import("../views/organization/organization.vue")
  },
  //客户中心
  {
    path: "/client",
    name: "Client",
    component: () => import("../views/client/Client.vue")
  },
  //客户信息
  {
    path: "/clientInfo",
    name: "ClientInfo",
    component: () => import("../views/client/ClientInfo.vue")
  },
  // 错误路由重定向
  {
    path: "*", // 错误路由
    redirect: "/login/password" //重定向
  }
];
const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  console.log(sessionStorage.getItem("token"));
  // let token = localStorage.getItem("token");
  if (sessionStorage.getItem("token")) {
    if (sessionStorage.getItem("id") || sessionStorage.getItem("role_id")) {
      next();
    } else {
      next({ path: "/login/password" });
    }
  } else {
    if (to.matched[0].name !== "Login") {
      next({ path: "/login/password" });
    } else {
      next();
    }
  }
});

export default router;
