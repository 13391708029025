const common = {
  position: ["管理员", "PM", "主美", "组长", "画师", "商务", "联系人"],
  bumens: ["营销部", "设计部", "财务部", "策划部"],
  levels: ["初级", "中级", "高级"],
  // levels: ["C", "B", "A", "S"],
  // status: ["待开始", "进行中", "暂停", "完成"],
  getDate(d1, d2) {
    if (d2) {
      let da1 = new Date(d1);
      let da2 = new Date(d2);
      return `${da1.getMonth() + 1}/${da1.getDate()}~${da2.getMonth() +
        1}/${da2.getDate()}`;
      // return `${this.buling(da1.getMonth() + 1)}/${this.buling(
      //   da1.getDate()
      // )}~${this.buling(da2.getMonth() + 1)}/${this.buling(da2.getDate())}`;
      // console.log(da1, da2);
    } else if (d1) {
      let d = new Date(d1);
      return `${d.getMonth() + 1}/${d.getDate()}`;
      // return `${this.buling(d.getMonth() + 1)}/${this.buling(d.getDate())}`;
      // console.log(d);
    } else {
      let date = new Date();
      let d =
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
      let t =
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
      return [d, t];
    }
    // console.log(d + " " + t);
  },
  judgeDate(d) {
    var td = new Date();
    let td2 = new Date(td.getFullYear(), td.getMonth(), td.getDate());
    var od = new Date(d * 1000);
    // console.log(od);
    let od2 = new Date(od.getFullYear(), od.getMonth(), od.getDate());
    var xc = (od2 - td2) / 1000 / 60 / 60 / 24;
    let odM = od.getMinutes() < 10 ? "0" + od.getMinutes() : od.getMinutes();
    if (xc < -2) {
      return (
        od.getFullYear() +
        "/" +
        (od.getMonth() + 1) +
        "/" +
        od.getDate() +
        "  " +
        od.getHours() +
        ":" +
        odM
      );
    } else if (xc < -1) {
      return "前天 " + od.getHours() + ":" + odM;
    } else if (xc < 0) {
      return "昨天 " + od.getHours() + ":" + odM;
    } else if (xc == 0) {
      return "今天 " + od.getHours() + ":" + odM;
    } else if (xc < 2) {
      return "明天";
    } else if (xc < 3) {
      return "后天";
    } else {
      return xc + "天后";
    }
  },
  postInterface(url, parem, callbackobj, callback) {
    let token = sessionStorage.getItem("token");
    var request = new XMLHttpRequest();
    request.onreadystatechange = function() {
      if (request.readyState == 4) {
        if (request.status == 200) {
          try {
            callback &&
              callback.call(callbackobj, JSON.parse(request.response));
          } catch (error) {
            console.log(error);
          }
        }
      }
    };
    request.open("POST", "//ghf.site.yscase.com" + url, true);
    // request.setRequestHeader("Content-Type", "applicationjson");
    //设置请求头
    request.setRequestHeader("token", token);
    var formData = new FormData();
    var keys = Object.keys(parem);
    keys.forEach(function(i) {
      if (parem[i] instanceof Object) {
        var keys2 = Object.keys(parem[i]);
        keys2.forEach(t => {
          formData.append(`${i}[${t}]`, parem[i][t]);
        });
      } else {
        formData.append(i, parem[i]);
      }
    });
    request.send(formData);
    // request.send(JSON.stringify(parem));
  },
  getInterface(url, parem, callbackobj, callback) {
    let token = sessionStorage.getItem("token");
    var request = new XMLHttpRequest();
    // console.log(request.readyState);
    // console.log(this.data);
    request.onreadystatechange = function() {
      if (request.readyState == 4) {
        if (request.status == 200) {
          try {
            callback &&
              callback.call(callbackobj, JSON.parse(request.response));
          } catch (error) {
            console.log(error);
          }
        }
      }
    };
    var keys = Object.keys(parem);
    keys.forEach(function(id, i) {
      if (i) {
        url += "&" + id + "=" + parem[id];
      } else {
        url += "?" + id + "=" + parem[id];
      }
    });
    request.open("GET", "//ghf.site.yscase.com" + url, true);
    // request.setRequestHeader("Content-Type", "applicationjson");
    //设置请求头
    request.setRequestHeader("token", token);
    request.send(null);

    // request.send(JSON.stringify(parem));
  },
  buling(num) {
    return num < 10 ? "0" + num : num;
  }
  
};
export default common;
