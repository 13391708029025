import axios from "axios";
const baseUrl = "https://smart.gomoretech.com/totalsmart-crm-server/";
// axios.defaults.timeout = 15000;
// axios.defaults.withCredentials = true;
// axios.defaults.crossDomain = true;
const token = sessionStorage.getItem("token") || ""; //获取user的值并保存
const enterprise = sessionStorage.getItem("enterprise") || ""; //获取password的值并保存
axios.defaults.headers["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers["token"] = token;
// axios.defaults.headers.post["token"] = token;
axios.defaults.headers["enterprise"] = enterprise;
// axios.defaults.headers.post["enterprise"] = enterprise;
//测试header参数

// axios.defaults.headers.get["token"] =
//   "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI1MjM2QSIsInN1YiI6IntcInVzZXJJZFwiOlwiOGEyZWI4MTM1MThmMWM1ODAxNTE4ZjFjNmM0NDAwOGFcIixcImxvZ2luXCI6XCJhZG1pblwiLFwidXNlck5hbWVcIjpcIui2hee6p-euoeeQhuWRmHRlc3RcIixcImVudGVycHJpc2VcIjpcIjhhMmViODEzNTE4ZjFjNTgwMTUxOGYxYzZjMDgwMDg5XCIsXCJzdG9yZVV1aWRcIjpcIjhhMmViODEzNTE4ZjFjNTgwMTUxOGYxYzZjMDgwMDg5XCJ9IiwiaWF0IjoxNjQ0MjE4OTkyLCJleHAiOjE2NDQ4MjM3OTJ9.7vqd9MO_2QDWO_Oqqu5oRfySJFKy3LuJ3HsNh0gTCTI";
// axios.defaults.headers.post["token"] =
//   "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI1MjM2QSIsInN1YiI6IntcInVzZXJJZFwiOlwiOGEyZWI4MTM1MThmMWM1ODAxNTE4ZjFjNmM0NDAwOGFcIixcImxvZ2luXCI6XCJhZG1pblwiLFwidXNlck5hbWVcIjpcIui2hee6p-euoeeQhuWRmHRlc3RcIixcImVudGVycHJpc2VcIjpcIjhhMmViODEzNTE4ZjFjNTgwMTUxOGYxYzZjMDgwMDg5XCIsXCJzdG9yZVV1aWRcIjpcIjhhMmViODEzNTE4ZjFjNTgwMTUxOGYxYzZjMDgwMDg5XCJ9IiwiaWF0IjoxNjQ0MjE4OTkyLCJleHAiOjE2NDQ4MjM3OTJ9.7vqd9MO_2QDWO_Oqqu5oRfySJFKy3LuJ3HsNh0gTCTI";
// axios.defaults.headers.get["enterprise"] = "8a2eb813518f1c5801518f1c6c080089";
// axios.defaults.headers.post["enterprise"] = "8a2eb813518f1c5801518f1c6c080089";

// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
const request = function(par) {
  if (par.responseType) {
    axios.defaults.responseType = par.responseType;
  } else {
    axios.defaults.responseType = null;
  }
  return new Promise((res, rej) => {
    switch (par.methond) {
      case "post":
        axios
          .post(baseUrl + par.url, {
            ...par.data
          })
          .then(response => {
            //如果是登录接口，请求成功空将token enterprise追加到请求头中
            if (par.url == "rest/user/login" && response.data.code == "0") {
              axios.defaults.headers["token"] = response.data.data.accessToken;
              axios.defaults.headers["enterprise"] =
                response.data.data.enterprise_uuid;
            }
            res(response);
          })
          .catch(err => {
            rej(err);
          });
        break;
      case "get":
        axios
          .get(baseUrl + par.url, {
            ...par.data
          })
          .then(response => res(response))
          .catch(err => {
            rej(err);
          });
        break;
    }
  });
};
export default request;
