<template>
  <div id="app">
      <!-- <AppBar> -->
    <router-view />
      <!-- </AppBar> -->
  </div>
</template>

<script>
export default {
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    // if (!this.$store.state.personal.length && sessionStorage.getItem("id")) {
    //   this.$common.getInterface(
    //     "/api/v1.workers/getOne",
    //     {
    //       id: sessionStorage.getItem("id")
    //     },
    //     this,
    //     res => {
    //       this.$store.commit("setPersonal", res.data);
    //     }
    //   );
    // }
  }
};
</script>

<style lang="less">
@deep: ~">>>";
body,
html {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  font-family: "Helvetica Neue", "Helvetica", "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", "Arial", "sans-serif";
}
.el-button {
  font-family: "Helvetica Neue", "Helvetica", "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", "Arial", "sans-serif";
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: "Helvetica Neue", "Helvetica", "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", "Arial", "sans-serif";
}
</style>
