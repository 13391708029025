import request from "@/request/request";

export default {
  // 登录接口
  login(userData) {
    return request({
      url: "rest/user/login",
      methond: "post",
      data: {
        ...userData
      }
    });
  },
  // 获取员工列表
  queryUser(data) {
    return request({
      url: "rest/user/query",
      methond: "post",
      data: {
        ...data
      }
    });
  },
  // 新增员工
  addUser(data) {
    return request({
      url: "/rest/user/save",
      methond: "post",
      data: {
        ...data
      }
    });
  },
  // 停用员工
  disableUser(id) {
    return request({
      url: "rest/user/disable/" + id,
      methond: "post"
    });
  },
  // 启用员工
  enableUser(id) {
    return request({
      url: "rest/user/enable/" + id,
      methond: "post"
    });
  },
  // 获取订单列表
  queryOrder(params) {
    return request({
      url: "rest/payorder/queryOrder",
      methond: "post",
      data: {
        ...params
      }
    });
  },
  // 导出订单信息
  exportOrder(params) {
    return request({
      url: "rest/payorder/exportOrder",
      methond: "post",
      data: {
        ...params
      },
      responseType: "blob"
    });
  },
  // 获取员工信息
  getUser(uuid) {
    return request({
      url: `rest/user/get/${uuid}`,
      methond: "get"
    });
  },
  // 获取角色列表
  getRoleAll() {
    return request({
      url: `rest/role/all`,
      methond: "get"
    });
  },
  // 新建角色
  saveRole(data, flag) {
    if (flag) {
      let params = {
        "createInfo": {
          "enterprise": null,
          "operator": {
            "id": data.creatorId,
            "operName": data.creator
          },
          "time": data.created
        },
        "lastModifyInfo": {
          "enterprise": null,
          "operator": {
            "id": data.lastModifierId,
            "operName": data.lastModifier
          },
          "time": data.lastModified
        },
        "version": data.version,
        "name": data.name,
        "uuid": data.uuid
      };
      return request({
        url: `rest/role/update`,
        methond: "post",
        data: { updated: [{ ...params }] }
      });
    } else {
      return request({
        url: `rest/role/save`,
        methond: "post",
        data: {
          ...data
        }
      });
    }
  },
  // 获取到所有的权限
  getAllActions() {
    return request({
      url: `rest/role/getAllActions`,
      methond: "get"
    });
  },
  // 获取到角色所有的权限
  getRolePermissions(roleUuid) {
    return request({
      url: `rest/role/getRolePermissions/${roleUuid}`,
      methond: "get"
    });
  },
  // 保存新的权限
  saveAuthorization(data) {
    return request({
      url: `rest/role/saveAuthorization`,
      methond: "post",
      data: {
        ...data
      }
    });
  },
  // 获取油站代码
  getAllStoresByOrgUuid(companyUuid) {
    return request({
      url: `rest/mdata/store/getAllStoresByOrgUuid/${companyUuid}`,
      methond: "get"
    });
  }
}